<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Marks Entry
            <v-spacer></v-spacer>
            <!--DO NOT DELETE-->
            <!--<div style="margin-top: 10px">-->
            <!--<v-fab-transition v-if="form.items.data.length && isDev()">-->
            <!--<v-tooltip bottom color="primary">-->
            <!--<template v-slot:activator="{on}">-->
            <!--<v-btn-->
            <!--v-on="on"-->
            <!--style="margin-right: 55px"-->
            <!--color="success"-->
            <!--fab-->
            <!--dark-->
            <!--small-->
            <!--right-->
            <!--bottom-->
            <!--@click="printSample"-->
            <!--&gt;-->
            <!--<v-icon>cloud_download</v-icon>-->
            <!--</v-btn>-->
            <!--</template>-->
            <!--<span>Generate Sample Excel</span>-->
            <!--</v-tooltip>-->
            <!--</v-fab-transition>-->
            <!--<v-fab-transition-->
            <!--v-if="!verify_status.is_verify && Object.keys(subject_marks).length && form.items.data.length>0 && $auth.can('marks-input-create&marks-input-update') && isDev()"-->
            <!--&gt;-->
            <!--<v-tooltip bottom color="primary">-->
            <!--<template v-slot:activator="{on}">-->
            <!--<v-btn-->
            <!--v-on="on"-->
            <!--style="margin-right: 110px"-->
            <!--color="warning"-->
            <!--fab-->
            <!--dark-->
            <!--small-->
            <!--right-->
            <!--bottom-->
            <!--@click="uploadDialog= true"-->
            <!--&gt;-->
            <!--<v-icon>attachment</v-icon>-->
            <!--</v-btn>-->
            <!--</template>-->
            <!--<span>Upload Marks</span>-->
            <!--</v-tooltip>-->
            <!--</v-fab-transition>-->
            <!--</div>-->
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs3 sm4>
                <v-select
                  :loading="examLoading"
                  :disabled="!exams.length"
                  :items="exams"
                  class="pa-0"
                  label="Exam"
                  v-model="exam"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm2>
                <v-select
                  :loading="gradeLoading"
                  :hint="selectedEvaluation"
                  persistent-hint
                  :items="grades"
                  class="pa-0"
                  label="Grade"
                  v-model="grade"
                  :disabled="!exam || !grades.length"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3 sm2>
                <v-select
                  :items="sections"
                  class="pa-0"
                  label="Section"
                  v-model="section"
                  :disabled="
                    !grade ||
                      Object.keys(hasSelectedGradePrePublished).length > 0
                  "
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs2 sm2>
                <v-select
                  :items="subjects"
                  :loading="subjectLoading"
                  class="pa-0"
                  label="Subjects"
                  v-model="subject"
                  outlined
                  dense
                  :disabled="
                    !grade ||
                      !subjects.length ||
                      Object.keys(hasSelectedGradePrePublished).length > 0
                  "
                />
              </v-flex>
              <v-flex xs2 sm2>
                <search-button
                  :disabled="
                    !subject ||
                      Object.keys(hasSelectedGradePrePublished).length > 0
                  "
                  permission="marks-input-read"
                  @action="get()"
                  >Search
                </search-button>
              </v-flex>
            </v-card-title>
          </v-card>
          <br />

          <v-container
            v-if="
              form.items.data.length &&
                !Object.keys(subject_marks).length &&
                form.items.data.length
            "
          >
            <strong style="color:#ff5252;font-size: 16px;">
              <v-icon color="#ff5252">warning</v-icon>
              {{ "Subject marks has not been set for " + subjectName }}
            </strong>
          </v-container>

          <div
            v-if="form.items.data.length && Object.keys(subject_marks).length"
            class="data-represent"
          >
            <div v-if="gradeRating === 'grading'">
              <span class="d-info"></span> &nbsp;
              <strong>{{ form.items.data.length }}</strong>
              <small> Total Students</small>
            </div>
            <div v-if="gradeRating === 'grading'">
              <span class="d-success"></span> &nbsp;
              <strong>{{ subject_marks.theory }}</strong>
              <small> Theory</small>
            </div>
            <div v-if="gradeRating === 'grading'">
              <span class="d-primary"></span> &nbsp;
              <strong>{{ subject_marks.practical }}</strong>
              <small> Practical</small>
            </div>

            <div>
              <span class="d-error"></span> &nbsp;
              <strong>{{ absentStudentsCount }}</strong>
              <small> Absent</small>
            </div>

            <div
              class
              style="float: right;margin-left: auto;margin-right: 40px;color:#f48e52;"
            >
              <strong v-if="!verify_status.is_verify && marks_entry.allowed">
                <v-icon small color="#f48e52">check_box</v-icon>
                Check to mark Absent.
              </strong>
              <strong
                style="color:#ff5252"
                v-if="!verify_status.is_verify && !marks_entry.allowed"
              >
                <v-icon small color="#ff5252">close</v-icon>
                Marks entry is not allowed until
                {{ marks_entry.duration.start }}.
              </strong>

              <strong
                v-if="verify_status.is_verify === 1"
                style="color:#777;font-size: 14px;font-weight: normal"
              >
                <v-icon small color="#4caf50">check_circle</v-icon>
                Marks Verified by
                <strong style="color:#444;">{{
                  verify_status.details.verified_by
                }}</strong>
              </strong>
            </div>
          </div>

          <div v-if="Object.keys(hasSelectedGradePrePublished).length < 1">
            <!--                    Grade/Numbering-->
            <v-data-table
              v-if="gradeRating === 'grading'"
              :headers="headers"
              :hide-default-footer="
                (form.items.data && form.items.data.length < 1) ||
                  form.items.meta.total < pagination.itemsPerPage
              "
              :items="resultList"
              :loading="form.loading"
              :options.sync="pagination"
              footer-props.items-per-page-options="rowsPerPageItems"
              class="marks_entry"
            >
              <template v-slot:item="{ index, item }">
                <tr>
                  <td class="text-xs-center">{{ item.student.roll }}</td>
                  <td class="text-xs-left">{{ item.student.name }}</td>
                  <td class="text-xs-center">
                    <span v-if="item.is_verify == 0">
                      <div class="marks_entry_input_container">
                        <strong
                          :class="{
                            absent_class: item.theory === 'Absent',
                          }"
                          v-if="verify_status.is_verify === 1"
                          >{{ item.theory }}
                        </strong>
                        <mark-input
                          v-if="
                            !verify_status.is_verify &&
                              $auth.can('marks-input-create')
                          "
                          className="mark_entry_input"
                          :disabled="
                            !Object.keys(subject_marks).length ||
                              item.theory === 'Absent'
                          "
                          v-model="item.theory"
                          @afterChange="checkTheoryVal($event, item, index)"
                        />

                        <v-checkbox
                          v-if="
                            !verify_status.is_verify &&
                              $auth.can('marks-input-create&marks-input-update')
                          "
                          @change="
                            updateMarksAbsentStatus(index), disabledSubmit()
                          "
                          v-model="item.theory"
                          value="Absent"
                          color="error"
                        ></v-checkbox>
                      </div>
                    </span>
                    <span v-else>{{ item.theory }}</span>
                  </td>
                  <td class="text-xs-center">
                    <span v-if="item.is_verify === 0">
                      <div class="marks_entry_input_container">
                        <strong
                          :class="{
                            absent_class: item.practical === 'Absent',
                          }"
                          v-if="verify_status.is_verify === 1"
                          >{{ item.practical }}</strong
                        >

                        <input
                          v-if="
                            !verify_status.is_verify &&
                              $auth.can('marks-input-create&marks-input-update')
                          "
                          :disabled="
                            !Object.keys(subject_marks).length ||
                              item.practical === 'Absent' ||
                              !subject_marks.practical
                          "
                          type="text"
                          @focus="$event.target.select()"
                          :style="
                            item.practical === 'Absent'
                              ? 'border:1px solid green;'
                              : ''
                          "
                          class="mark_entry_input"
                          v-model="item.practical"
                          @keyup="checkPracticalVal($event, item, index)"
                        />
                        <v-checkbox
                          v-if="
                            !verify_status.is_verify &&
                              subject_marks.practical &&
                              $auth.can('marks-input-create&marks-input-update')
                          "
                          @change="
                            updateMarksAbsentStatus(index), disabledSubmit()
                          "
                          v-model="item.practical"
                          value="Absent"
                          color="error"
                        ></v-checkbox>
                      </div>
                    </span>
                    <span v-else>{{ item.practical }}</span>
                  </td>
                </tr>
              </template>
              <v-alert
                slot="no-results"
                :value="true"
                color="error"
                icon="warning"
                >Your search for "{{ search }}" found no results.
              </v-alert>
            </v-data-table>

            <v-data-table
              v-if="gradeRating === 'rating'"
              :headers="headersRating"
              :hide-default-footer="
                (form.items.data && form.items.data.length < 1) ||
                  pagination.totalItems < pagination.rowsPerPage
              "
              :items="form.items.data"
              :loading="form.loading"
              :options.sync="pagination"
              footer-props.items-per-page-options="rowsPerPageItems"
              class="marks_entry"
            >
              <template v-slot:item="{ index, item }">
                <tr :class="''">
                  <td class="text-xs-center">{{ item.student.roll }}</td>
                  <td class="text-xs-left">{{ item.student.name }}</td>
                  <td class="text-xs-left">
                    <div style="display: flex; line-height: 43px">
                      <div style="flex: 2">
                        <!--{{form.items.data[index].theory}}-->
                        <span
                          class="absentSt"
                          v-if="
                            form.items.data[index].theory === 9999 ||
                              form.items.data[index].theory === 'Absent' ||
                              form.items.data[index].theory === 'I'
                          "
                          >Absent</span
                        >
                        <span v-else>
                          <v-rating></v-rating>
                        </span>
                      </div>
                      <div
                        v-if="!verify_status.is_verify"
                        style="margin-top: 8px; flex: 1"
                      >
                        <v-checkbox
                          v-if="
                            !verify_status.is_verify &&
                              $auth.can('marks-input-create&marks-input-update')
                          "
                          @change="
                            updateMarksAbsentStatus(index), disabledSubmit()
                          "
                          v-model="form.items.data[index].theory"
                          value="Absent"
                          color="#f48e52"
                        ></v-checkbox>
                      </div>
                    </div>
                  </td>
                  <td class="text-xs-center">
                    <v-text-field
                      v-if="!verify_status.is_verify"
                      v-model="form.items.data[index].comments"
                    ></v-text-field>
                    <p v-else>{{ form.items.data[index].comments }}</p>
                  </td>
                </tr>
              </template>
              <v-alert
                slot="no-results"
                :value="true"
                color="error"
                icon="warning"
                >Your search for "{{ search }}" found no results.
              </v-alert>
            </v-data-table>
          </div>
          <div style="padding: 40px;" v-else>
            <AlertMessage
              :message="
                `Looks like '${hasSelectedGradePrePublished.text}' > Grade '${selectedGradeName}' is still on process. Please publish the exam before you continue. `
              "
              title="Exam Publish Pending"
              type="error"
            />
          </div>

          <v-card-actions>
            <v-checkbox
              v-if="
                !verify_status.is_verify &&
                  Object.keys(subject_marks).length &&
                  form.items.data.length > 0 &&
                  $auth.can('verify-marks-exam|exam-verify-marks')
              "
              style="margin-right: 20px;"
              color="blue"
              v-model="is_verify"
            >
              <template v-slot:label>
                <div style="color:#333;">
                  Check to verify subject marks
                  <p style="line-height: 10px">
                    <small style="color:#666;font-size: 10px;"
                      >Caution: Subject marks once verified won't be editable in
                      future.
                    </small>
                  </p>
                </div>
              </template>
            </v-checkbox>
            <div
              v-if="verify_status.is_verify === 1 && form.items.data.length > 0"
              style="display: flex;width: 300px;margin-top: 20px;"
            >
              <div style="width: 30px;">
                <v-icon color="success">check_box</v-icon>
              </div>
              <div>
                <h3 style="color: #777;">
                  Verified by
                  <strong style="color: #333;">{{
                    verify_status.details.verified_by
                  }}</strong>
                </h3>
                <p style="color: #888;">
                  on {{ verify_status.details.verified_date }}
                </p>
              </div>
            </div>
          </v-card-actions>
          <v-card-actions>
            <!--<v-btn-->
            <!--block-->
            <!--large-->
            <!--v-if="verify_status.is_verify && Object.keys(subject_marks).length && form.items.data.length>0 && $auth.can('verify-marks-exam|exam-verify-marks')"-->
            <!--@click="unVerifyMarks()"-->
            <!--&gt;Un Verify-->
            <!--</v-btn>-->

            <edit-button
              :loading="saving"
              :large="true"
              @agree="unVerifyMarks()"
              editText="Remove Verification"
              :block="true"
              :permission="true"
              v-if="
                verify_status.is_verify &&
                  Object.keys(subject_marks).length &&
                  form.items.data.length > 0 &&
                  $auth.can('verify-marks-exam|exam-verify-marks') &&
                  selectedGrade.is_publish !== 1
              "
              label="Remove Verification"
            ></edit-button>
            <v-spacer></v-spacer>
            <v-btn
              :loading="saving"
              large
              block
              message="Are you sure you want to un verify the verified entry."
              color="primary"
              v-if="
                !verify_status.is_verify &&
                  Object.keys(subject_marks).length &&
                  form.items.data.length > 0 &&
                  $auth.can('marks-input-create&marks-input-update')
              "
              :outlined="false"
              :disabled="dataVerfiy || saving"
              @click="save"
            >
              {{ form.is_update ? "Update" : "Save" }}
            </v-btn>
          </v-card-actions>
          <v-dialog v-model="uploadDialog" persistent max-width="600">
            <v-card>
              <v-card-title class="title pa-3">
                <div>
                  <input
                    type="file"
                    id="file"
                    ref="file"
                    v-on:change="handleFileUpload()"
                  />
                </div>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  outlined
                  small
                  @click="uploadFormReset, (uploadDialog = false)"
                  >Cancel
                </v-btn>
                <v-btn
                  :disabled="!file"
                  color="warning"
                  outlined
                  small
                  @click="uploadFile"
                  >save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import { passDataToExternalDomain } from "../../../../library/helpers";
import NavFactory from "../../../../library/NavFactory";
import MarkInput from "./MarkInput";
export default {
  components: {
    MarkInput,
  },
  data: () => ({
    examLoading: false,
    gradeLoading: false,
    subjectLoading: false,
    rating: [],
    saving: false,
    form: new Form(
      {
        grade_id: "",
        subject_id: "",
        section_id: "",
        exam_id: "",
        students: [],
        is_update: false,
        is_verify: 0,
      },
      "/api/marksheet"
    ),
    uploadForm: new Form(
      {
        file: "",
        section_id: "",
        grade_id: "",
        exam_id: "",
        subject_id: "",
      },
      "api/marks-upload"
    ),

    search: null,
    pagination: {
      itemsPerPage: 100,
      sortBy: ["roll"],
    },
    rowsPerPageItems: [5, 10, 25, 50, 75, 100, 150],
    is_verify: 0,
    dataVerfiy: false,
    subject: "",
    subjects: [],
    section: "",
    sections: [],
    exam: "",
    exams: [],
    grade: "",
    grades: [],
    subject_marks: {},
    headers: [
      {
        text: "Roll",
        align: "center",
        value: "roll",
        width: 50,
        sortable: false,
      },
      { text: "Name", align: "left", value: "name", sortable: false },
      { text: "Theory", align: "center", value: "theory", sortable: false },
      {
        text: "Practical",
        align: "center",
        value: "practical",
        sortable: false,
      },
    ],
    headersRating: [
      { text: "Roll", align: "center", value: "roll", width: 50 },
      { text: "Name", align: "left", value: "name", sortable: false },
      {
        text: "Rating",
        align: "center",
        value: "rating",
        width: 250,
        sortable: false,
      },
      { text: "Comment", align: "center", value: "comments", sortable: false },
    ],
    verify_status: {},
    subject_marks_theory: 0,
    subject_marks_practical: 0,
    marks_entry: {},
    evaluation: "",
    samplePrintUrl: "",
    disablePdf: false,
    gradeRating: "",
    uploadDialog: false,
    file: "",
    hasSelectedGradePrePublished: {},
    selectedGradeName: "",
    selectedGrade: {},
    resultList: [],
  }),
  computed: {
    ...mapState(["batch"]),
    subjectName() {
      let subjectName = "";
      if (this.subject) {
        let $this = this;
        this.subjects.map(function(subject) {
          if (subject.value === $this.subject) subjectName = subject.text;
        });
      }
      return subjectName;
    },
    absentStudentsCount() {
      let count = 0;
      this.form.items.data.map((item) => {
        if (
          item.theory === "Absent" ||
          item.practical === "Absent" ||
          item.theory === 9999
        ) {
          count++;
        }
      });
      return count;
    },
    selectedEvaluation() {
      let grade = this.grade;
      let output = "";
      let $this = this;
      this.grades.map((item) => {
        if (grade === item.value && item.evaluation) {
          $this.selectedGrade = item;
          $this.selectedGradeName = item.text;
          output = "Evaluation " + item.evaluation + "%";
        }
      });
      return output;
    },
  },
  mounted() {
    this.getExams();
  },
  watch: {
    batch: function(value) {
      this.get();
    },
    grade: function(v) {
      this.section = "";
      this.subject = "";
      this.form.items.data = [];
      if (v) {
        this.getSections();
        this.getSubjects();
        this.getGradeRatingStatus();
        this.checkIsPreviousExamPublished(v);
      }
    },
    exam: function() {
      this.grade = "";
      this.form.items.data = [];
      this.getGrades();
    },
    subject: function() {
      // this.section = '';
      this.form.items.data = [];
      // this.get();
    },
    section: function(v) {
      this.subject = "";
      if (v) this.getSubjects();
    },
    pagination: function() {
      this.get();
    },
  },

  methods: {
    isDev: NavFactory.isDev,
    passDataToExternalDomain,

    checkIsPreviousExamPublished(gradeId) {
      let previousExam = null;
      this.hasSelectedGradePrePublished = {};
      this.exams.map((ex, i) => {
        if (i !== 0 && ex.value === this.exam) {
          previousExam = this.exams[this.exams.indexOf(ex) - 1];
        }
      });

      if (!previousExam) return false;

      previousExam.exam_grades.map((exGrade) => {
        if (gradeId === exGrade.grade_id && exGrade.is_publish === 0) {
          this.hasSelectedGradePrePublished = previousExam;
        }
      });
    },

    unVerifyMarks() {
      let id = this.verify_status.id;
      this.saving = true;
      this.$rest
        .put(
          "api/exam-verify-log/" +
            id +
            "?examId=" +
            this.exam +
            "&gradeId=" +
            this.grade
        )
        .then(({ data }) => {
          this.get();
        })
        .finally(() => {
          this.saving = false;
        });
    },
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function(key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&") +
        "&gradeId=" +
        this.grade.toString() +
        "&examId=" +
        this.exam.toString() +
        "&subjectId=" +
        this.subject.toString() +
        "&sectionId=" +
        this.section.toString()
      );
    },

    get(params) {
      if (this.section && this.subject) {
        let query = [null, undefined].includes(params)
          ? this.queryString()
          : params;
        query = query.replace("&descending=false&page=1", "");
        this.form.get(null, query).then(({ data }) => {
          this.resultList = data.data;
          this.form.is_update = data.is_update;
          this.samplePrintUrl = data.download_url;
          this.pagination.totalItems = data.meta.total;
          this.subject_marks = data.subject_marks || {};
          if (this.subject_marks && Object.keys(this.subject_marks).length) {
            this.subject_marks_theory = data.subject_marks.theory;
            this.subject_marks_practical = data.subject_marks.practical;
            this.marks_entry = data.marks_entry;

            this.headers[2].text = "Theory(" + data.subject_marks.theory + ")";
            this.headers[3].text =
              "Practical(" + data.subject_marks.practical + ")";
          }
          this.verify_status = data.verify_status || {};
          this.is_verify = data.verify_status
            ? data.verify_status.is_verify
            : 0;
        });
      }
    },

    save() {
      this.form.students = this.form.items.data.map(function(item) {
        if (item.theory === "Absent" || item.theory === 9999) {
          item.theory = "I";
        }
        if (item.practical === "Absent" || item.theory === 9999) {
          item.practical = "I";
        }
        return item;
      });
      this.form.grade_id = this.grade;
      this.form.exam_id = this.exam;
      this.form.subject_id = this.subject;
      this.form.section_id = this.section;
      this.form.is_verify = this.is_verify ? 1 : 0;
      this.form.fireFetch = false;
      this.saving = true;
      this.form
        .store()
        .then((res) => {
          this.is_verify = 0;
          this.get();
          this.form.is_update = true;
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getGrades() {
      this.gradeLoading = true;
      this.hasSelectedGradePrePublished = false;
      this.$rest
        .get(
          "/api/exam-grade?rowsPerPage=25&sortBy=rank&descending=true&slim=true&examId=" +
            this.exam
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return {
              value: item.grade_id,
              text: item.name,
              evaluation: item.evaluation,
              is_publish: item.is_publish,
            };
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },
    getExams() {
      this.examLoading = true;
      this.$rest
        .get("/api/exam")
        .then(({ data }) => {
          this.exams = data.data.map((item) => {
            return {
              value: item.id,
              text: item.name,
              exam_grades: item.examgrades,
            };
          });
        })
        .finally(() => {
          this.examLoading = false;
        });
    },
    getSections() {
      this.$rest
        .get("/api/section?batch=" + this.batch.id + "&grade=" + this.grade)
        .then(({ data }) => {
          this.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    getSubjects() {
      this.subjectLoading = true;
      this.$rest
        .get(
          "/api/mark-subject-list?rowsPerPage=20&gradeId=" +
            this.grade +
            "&examId=" +
            this.exam +
            "&sectionId=" +
            this.section
        )
        .then(({ data }) => {
          this.subjects = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally(() => {
          this.subjectLoading = false;
        });
    },
    editData(subject) {
      this.subject = subject;
    },
    checkPracticalVal(e, value, i) {
      this.disabledSubmit();
      if (value.practical > this.subject_marks.practical) {
        e.target.style.border = "1px solid red";
        value.practical = parseFloat(this.subject_marks.practical) + 1;
        e.target.style.color = "red";
      } else {
        e.target.style.border = "1px solid #ccc";
        e.target.style.color = "black";
      }
    },
    checkTheoryVal(e, value, i) {
      this.disabledSubmit();
      if (value.theory > this.subject_marks.theory) {
        e.target.style.border = "1px solid red";
        value.theory = parseFloat(this.subject_marks_theory) + 1;
        e.target.style.color = "red";
      } else {
        e.target.style.border = "1px solid #ccc";
        e.target.style.color = "black";
      }
    },

    disabledSubmit() {
      let count = this.form.items.data.length;
      let j = 0;
      let i = 0;
      this.form.items.data.map((res) => {
        if (
          res.theory <= this.subject_marks_theory ||
          res.theory == "Absent" ||
          res.theory == 9999
        ) {
          j++;
        }
      });
      this.form.items.data.map((res) => {
        if (
          res.practical <= this.subject_marks_practical ||
          res.practical == "Absent" ||
          res.practical == 9999
        ) {
          i++;
        }
      });
      if (j !== count || i !== count) {
        this.dataVerfiy = true;
      } else {
        this.dataVerfiy = false;
      }
    },

    updateMarksAbsentStatus(index) {
      this.form.items.data[index].comments = "";
      if (this.form.items.data[index].theory === null) {
        this.form.items.data[index].theory = 0;
      }

      if (this.form.items.data[index].practical === null) {
        this.form.items.data[index].practical = 0;
      }
    },
    getGradeRatingStatus() {
      this.$rest.get("api/marksheet-type?gradeId=" + this.grade).then((res) => {
        this.gradeRating = res.data.marksheet_type;
      });
    },
    printSample() {
      passDataToExternalDomain("reporting");
      if (this.disablePdf === false) {
        this.disablePdf = true;
        let $this = this;
        setTimeout(function() {
          window.open($this.samplePrintUrl + "excel");
          $this.disablePdf = false;
        }, 500);
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    uploadFormReset() {
      this.file = "";
      document.querySelector("#file").value = "";
    },
    uploadFile() {
      this.uploadForm.grade_id = this.grade;
      this.uploadForm.section_id = this.section;
      this.uploadForm.exam_id = this.exam;
      this.uploadForm.subject_id = this.subject;
      this.uploadForm.file = this.file;
      this.uploadForm.fireFetch = false;
      this.uploadForm
        .store()
        .then((data) => {
          this.uploadDialog = false;
          this.uploadFormReset();
          this.get();
        })
        .then((data) => {})
        .catch((e) => {
          this.uploadFormReset();
        });
    },
  },
};
</script>
<style lang="scss">
.marks_entry {
  input[disabled] {
    background-color: #eee;
    color: #ccc !important;
    border-color: #ccc !important;
    cursor: not-allowed;
  }

  input[value="0"] {
    color: #ccc !important;
    border-color: #ccc !important;
  }

  .mark_entry_input {
    border: 1px solid #ccc;
    font-weight: bold;
    border-radius: 3px;
    width: 60px;
    height: 25px;
    text-align: center;
  }

  .marks_entry_input_container {
    display: flex;
    align-items: center;
    justify-content: center;

    .v-input__slot {
      margin-bottom: 0 !important;
    }

    .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
      margin-bottom: 0 !important;
    }

    .v-input__control {
      display: -webkit-box !important;
      -webkit-box-orient: horizontal !important;
    }

    .v-input {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      display: -webkit-box !important;
      display: -ms-flexbox;
      /* display: flex; */
      /* -webkit-box-flex: 1; */
      -ms-flex: 1 1 auto;
      flex: none;
      font-size: 16px;
      text-align: left;
    }
  }

  input:focus {
    outline: none;
  }
}

.v-input--selection-controls {
  margin-right: 20px !important;
  flex: none !important;
}

span.absentSt {
  background: gray;
  color: white;
  border-radius: 5px;
  padding: 3px;
}
.absent_class {
  color: #ff5252 !important;
}
</style>
